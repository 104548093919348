<template lang="html">
	<div class="flex flex-col h-full border-l border-beige-dark">
		<!-- <div>
			<pre>{{ params }}</pre>
			<pre class="h-64 overflow-auto">{{ users }}</pre>
			<pre class="h-64 overflow-auto">{{ departments }}</pre>
		</div>
		-->
		<!-- <button @click="showPickUser = !showPickUser">showPickUser true</button> -->
		
		<div 
			class="fixed inset-0 bg-white flex flex-col" style="z-index: 1001;"
			v-if="showPickUserBeforeGivingKudos">
			
			<div class="flex-grow">
				<UserPick 
					@onUserClick="onCreateKudo( {
						transaction: showPickUserBeforeGivingKudos.transaction,
						symbol: showPickUserBeforeGivingKudos.symbol,
						userPassed: $event,
					}); showPickUserBeforeGivingKudos = null;"
				/>
			</div>
			<div class="flex-none px-6 py-6 flex space-x-4">
				<a 
					class="button w-full" 
					@click="showPickUserBeforeGivingKudos = null;">
					
					Avbryt
				</a>
			</div>
		</div>
		
		<div class="flex-none p-6 space-y-6">
			<div class="flex w-full">
				<select 
					v-model="departmentId"
					@change="onChangeDepartment()"
					id="departmentId"
					class="flex-grow">
					
					<option 
						:key="'departmentId_all'"
						:value="null">
						
						Alle avdelinger
					</option>
					
					<option 
						v-for="(department,index) in departments"
						:key="'departmentId_'+department.departmentId+'_'+index"
						:value="department.departmentId">
						
						Avdeling: {{department.name}}
					</option>
				</select>
			</div>
			
			<div class="flex gap-4">
				<a 
					class="flex-1 text-center rounded-lg px-3 py-1 bg-beige" 
					:disabled="loading"
					@click="onGotoPreviousMonth()">
					
					Forrige
				</a>
				
				<a 
					class="flex-grow text-center px-3 py-1 capitalize text-black"
					@click="onFetchTransactions()">
					
					{{ from | moment('MMM YYYY') }}
				</a>
				
				<a 
					class="flex-1 text-center rounded-lg px-3 py-1 bg-beige" 
					:disabled="loading"
					@click="onGotoNextMonth()">
					
					Neste
				</a>
			</div>
		</div>
		
		<div 
			class="flex-grow overflow-hidden flex items-center justify-center"
			v-if="loading && loading == 'fetching'">
			
			<div 
				class="spinner mx-auto"
			/>
		</div>
		<div 
			class="flex-grow overflow-auto p-6 bg-white"
			v-else-if="transactionsFiltered?.length">
			
			<div
				v-for="t in transactionsFiltered"
				:key="'departmentTransaction_'+t.transactionId">
				
				<TransactionCard 
					:transaction="t"
					:users="users"
					type="user"
				/>
				
				<div 
					v-if="kudos"
					class="flex py-4 justify-end">
					
					<div class="flex-grow">
						<div 
							class="truncate px-3 w-64 opacity-50"
							v-if="kudos.filter( kudo => kudo.transactionId == t.transactionId).length">
							
							{{ 
								kudos
									.filter( kudo => kudo.transactionId == t.transactionId)
									.reduce( (accumulator, current) => {
										if (!accumulator.find( (item) => item.from.username === current.from.username)) {
											accumulator.push(current);
										}
										return accumulator;
									}, [])
									.map( kudo => {
										return kudo.from?.user?.given_name + ' '+ kudo.from?.user?.family_name;
									})
									.join(', ')
							}}
						</div>
					</div>
					
					<div class="flex-none space-x-4 flex">
						<div 
							class="flex cursor-default"
							v-for="symbol in ['heart', 'thumbsUp']"
							:key="'symbol_'+t.transactionId+'_'+symbol">
							
							<div 
								class="rounded-full flex items-center justify-center space-x-1 px-2 h-8"
								:class="kudos.filter( kudo => kudo.transactionId == t.transactionId && kudo.symbol == symbol && kudo?.from.username == user?.username).length ? `border border-green bg-green text-white` : 'border border-beige-dark'"
								@click="onCreateKudo({
									transaction: t, 
									symbol,
								})">
								
								<i 
									class="fas w-6 text-center fa" 
									:class="symbol == 'thumbsUp' ? 'fa-thumbs-up' : 'fa-heart'"
								/>
								
								<div 
									v-if="kudos.filter( kudo => kudo.transactionId == t.transactionId && kudo.symbol == symbol).length"
									class="text-center px-1 w-8">
									
									{{ kudos.filter( kudo => kudo.transactionId == t.transactionId && kudo.symbol == symbol).length }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div 
			v-else
			class="text-center">
			
			Fant ingen aktivitet i denne perioden.
		</div>
		
	</div>
</template>

<script>

export default {
	components: {
		'TransactionCard': () => import('@/components/transactions/TransactionCard.vue'),
		'UserPick': () => import('@/components/users/UserPick.vue'),
    },
	
	props: { 
		departments: {
			type: Array,
			required: true,
		},
		
		user: {
			type: Object,
			required: false,
		},
		
		users: {
			type: Array,
			required: true,
		},
	},
	
	computed: {
		params(){
			return {
				departmentId: this.departmentId,
				from: this.from,
				to: this.to,
			};
		},
		
		prevMonth(){
			return 'prevMonth';
		},
		
		nextMonth(){
			return 'nextMonth';
		},
		
		transactionsFromWebSocket(){
			const transactionsFromWebSocket = this.$store.getters.getTransactionsFromWebSocket;
			console.log('got transactionsFromWebSocket!', transactionsFromWebSocket);
			return transactionsFromWebSocket;
		},
		
		transactionsFiltered(){
			let allTransactions = [
				...this.transactions,
				...this.transactionsFromWebSocket,
			];
			
			const uniqueTransactions = [...new Map( allTransactions.map( t => [t.transactionId, t]) ).values()]
			return uniqueTransactions.sort((a, b) => b.createdAt > a.createdAt ? 1 : -1);
		}
	},
	
	data(){
		return {
			loading: null,
			transactions: [],
			from: null,
			to: null,
			kudos: [],
			departmentId: null,
			showPickUserBeforeGivingKudos: null,
		}
	},
	
	watch: {
		transactionsFromWebSocket(newVal) {
			if (newVal) {
				console.log('transactionsFromWebSocket newVal', newVal);
			}
		},
	},
	methods: {
		onGotoPreviousMonth(){
			this.from = this.$moment( this.from ).subtract( 1, 'month').startOf('month').valueOf();
			this.to = this.$moment( this.from ).endOf('month').valueOf();
			this.onFetchTransactions();
		},
		
		onGotoNextMonth(){
			this.from = this.$moment( this.from ).add( 1, 'month').startOf('month').valueOf();
			this.to = this.$moment( this.from ).endOf('month').valueOf();
			this.onFetchTransactions();
		},
		
		onChangeDepartment(){
			this.onFetchTransactions();
		},
		
		async onFetchTransactions(){
			try {
				this.loading = 'fetching';
				
				let transactionsInPeriod = null;
				let kudosInPeriod = null;
				
				if (this.account.accessToken) {
					// is portal mode
					// const queryParams = '?departmentId='+this.params.departmentId;
					
					let queryParams;
					
					if (this.params.departmentId) {
						// const queryParams = '?departmentId='+this.params.departmentId+'&from='+this.params.from+'&to='+this.params.to;
						queryParams = '?departmentId='+this.params.departmentId+'&from='+this.params.from+'&to='+this.params.to;
					}
					else {
						queryParams = '?from='+this.params.from+'&to='+this.params.to;
					}
					
					const responseTransactions = await this.$axios.get(process.env.VUE_APP_API_ENDPOINT +'/portal/transactions'+queryParams, {
						headers: {
							authorization: this.account.accessToken,
						},
					});
					
					const responseKudos = await this.$axios.get(process.env.VUE_APP_API_ENDPOINT +'/portal/kudos'+queryParams, {
						headers: {
							authorization: this.account.accessToken,
						},
					});
					
					transactionsInPeriod = responseTransactions.data;
					kudosInPeriod = responseKudos.data;
				}
				else {
					transactionsInPeriod = await this.$store.dispatch('listTransactions', this.params );
					kudosInPeriod = await this.$store.dispatch('listKudos', this.params );
				}
				
				this.transactions = transactionsInPeriod;
				this.kudos = kudosInPeriod;
			} 
			catch (e) {
				this.$notify({ 
					type: 'error', 
					text: e.message || 'Kunne ikke hente transaksjoner'
				});
				
				console.error(e);
			} 
			finally {
				this.loading = null;
			}
		},
		
		async onCreateKudo( {transaction, symbol, userPassed} ){
			try {
				let user;
				
				if (!this.user && !userPassed) {
					// set the onCreateKudo object, so it can pass this along when the user gets picked :) 
					this.showPickUserBeforeGivingKudos = {
						transaction, 
						symbol,
					};
					return;
				}
				else if (userPassed) {
					user = userPassed;
				}
				else {
					user = this.user;
				}
				
				if (transaction.username == user?.username) {
					throw Error('Du kan ikke gi deg selv kudos.');
				}
				
				console.log('this kudo!');
				
				console.log({
					transaction, 
					symbol, 
					userPassed,
					user,
				});
				
				this.loading = 'creatingKudo';
				
				let kudo = {
					transactionId: transaction.transactionId,
					departmentId: transaction.departmentId,
					username: transaction.username,
					from: {
						username: user?.username,
					},
					symbol,
				};
				
				console.log({kudo});
				
				const kudosWithSameSymbolFromThisUsername = this.kudos
					.filter( kudo => kudo.transactionId == transaction.transactionId)
					.filter( kudo => kudo.symbol == symbol)
					.filter( kudo => kudo.from.username == user.username);
				
				console.log( {kudosWithSameSymbolFromThisUsername} );
				
				if (kudosWithSameSymbolFromThisUsername.length) {
					throw Error('Denne har du gitt allerede');
				}
				
				if (this.account.accessToken) {
					// insert from user if is portal mode
					
					kudo.from.user = {
						email: user.email,
						family_name: user.family_name,
						given_name: user.given_name,
						picture: user.picture,
					};
					
					const response = await this.$axios.post(process.env.VUE_APP_API_ENDPOINT +'/portal/kudo', 
						kudo,
						{
							headers: {
								authorization: this.account.accessToken,
							},
						}
					);
					
					if (!response?.data) {
						throw Error();
					}
					
					console.log('kudo response.data', response.data);
					
					this.kudos.push( response.data );
				}
				else {
					const response = await this.$store.dispatch('createKudo', kudo);
					if (!response) {
						throw Error();
					}
					
					this.kudos.push( response );
				}
				
				this.$notify({ type: 'success', text: 'Kudos ble gitt' });
			} 
			catch (e) {
				this.$notify({ 
					type: 'error', 
					text: e.message || 'Kunne ikke gi kudos'
				});
			
				console.error(e);
			} 
			finally {
				this.loading = null;
			}
		},
	},
	
	async mounted(){
		this.from = this.$moment().startOf('month').valueOf();
		this.to = this.$moment().endOf('month').valueOf();
		
		console.log( this.params );
		await this.onFetchTransactions();
	},
}
</script>